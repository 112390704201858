.loadingPageWrapper {
    height: 85vh;
    display: grid;
}

.loadingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.loadingPageWrapper img {
    width: 30dvh;
    align-items: center;
    justify-content: center;
}

.loadingContainer p {
    padding-top: 5%;
    text-align: center;
    font-size: 4dvh;
  }

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.loadingPage {
    width: 100%;
    height: 100vh;
}

@media (max-width: 766px){
    .loadingPageWrapper img {
        width: 40%;
    }
  }