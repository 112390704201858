.dialog-loading {
    background-color: #5a4bff1b;
    padding: 1%;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }

  .dialog-content-loading {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #392bd579;
    color: white;
    padding: 2%;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    border: 2px solid rgba(0, 0, 0, 0.608);
    box-shadow: 0 4px 6px #392bd5;
    color: white;
    font-weight: bold;
    border-radius: 10px;
  }

  .loading-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
  }

  .loading-section img {
    width: 50%
  }