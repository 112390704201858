.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    width: 50dvh;
    gap: 1dvh;
  }
  
  .login-form input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .login-button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .login-button:disabled {
    background-color: #194f967e;
    color: rgba(255, 255, 255, 0.132);
    border: none;
    border-radius: 5px;
    cursor: not-allowed;
  }
  .cancel-button {
    padding: 10px;
    background-color: #dd5151a5;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .wrapper {
    height:100dvh;
    display: grid;
    padding-bottom: 15dvh;
  }

  .login-button img{
    max-width: 10%;
    margin: 0;
    padding: 0;
  }

  .logoAppWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logoAppWrapper img:first-child {
    width: 100%;
    margin: 0px;
    padding: 0px;
  }

  @media (max-width: 1000px) {
    .login-form {
      display: flex;
      flex-direction: column;
      width: 45dvh;
      gap: 1dvh;
    }
  }

  @media (max-width: 525px) {
    .login-form {
      display: flex;
      flex-direction: column;
      width: 40dvh;
      gap: 1dvh;
    }
  }