/* DialogBox.css */
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above everything */
}

.dialog {
  background-color: #5A4BFF;;;
  padding: 1%;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(98, 98, 98, 1);
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.dialog-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #392bd5;
  color: white;
  padding: 2%;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  border: 2px solid black;
  box-shadow: 0 4px 6px #392bd5;
  color: white;
  font-weight: bold;
  border-radius: 10px;
}

.dialog-text-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height:100%;
  background: rgba(0, 0, 0, 0.328);
  border-radius: 10px;
  padding: 2%;

}
.dialog-content input{
  text-align: center;
  color: black;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  min-height: 2.5rem;
  width: 100%;
}
.dialog-content button {
  margin: 10px;
  padding: 20px;
  width: 100%;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #02071C;
  color: white;
  font-weight: bold;
}

.buttons-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 1rem;

}
.disconnect-button {
  background-color: var(--removeButton) !important;
  color: black !important;
  font-weight: bold !important;
  border: 2px solid rgba(0, 0, 0, 0.434) !important;
}

.confirm-button:disabled {
  background-color: var(--confirmButtonDisabled) !important;
  color: rgba(0, 0, 0, 0.344) !important;
  font-weight: bold !important;
  border: 2px solid rgba(0, 0, 0, 0.434) !important;
}


.confirm-button {
  background-color: var(--confirmButton) !important;
  color: black !important;
  font-weight: bold !important;
}

/* Media */
@media (max-width: 1000px) {

}

@media (max-width: 950px){
  .dialog {
    width: 90%;
  }
  
}