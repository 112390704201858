.HeroWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100vh;
}

.HeroLeft, .HeroRight {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #000;
}

.HeroLeft {
    flex: 1
}
.HeroRight {
    flex: 2
}

.Sec1Wrapper, .Sec2Wrapper, .Sec3Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    border: 1px solid #000;
}

.Footer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
    width: 100%;
    border: 1px solid #000;
}