.ronnin-wallet-connection-wrapper {
    width: 90%;
}

.login-button-ronin {
    background-color: #007bff; /* Button color */
    color: white; /* Button text color */
    border: none; /* Remove border */
    border-radius: 7px; /* Rounded corners */
    cursor: pointer; /* Pointer on hover */
    transition: background-color 0.3s; /* Smooth transition */
    width: 100%;
    display: flex;
    justify-content: center;
}

.logged-button-ronin {
    background-color: #007bff1a; /* Button color */
    color: white; /* Button text color */
    border: none; /* Remove border */
    border-radius: 7px; /* Rounded corners */
    cursor: pointer; /* Pointer on hover */
    transition: background-color 0.3s; /* Smooth transition */
    font-size: 1.5rem;
    width: 95%;
    display:flex;
    justify-content: center;
    margin-left: 5%;
    /* margin-bottom: 5%; */
}

.connect-ronin-wallet-button-wrapper {
    display: flex; /* Enable flexbox for the container */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    flex-direction: column;
    border-radius: 0.5rem; /* Rounded corners */
    /* padding-top: 0.5rem;
    padding-bottom: 0.5rem; */
    width: 100%;

}

.connect-ronin-wallet-button-wrapper-half {
    flex-direction: row;
    /* padding-top: 1rem;
    padding-bottom: 1rem; */

}
.connect-ronin-wallet-button-wrapper span {
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
    width: 100%;
    padding-bottom: 5%;
    
}


.connect-ronin-wallet-button-wrapper img {
    max-width: 10%;
    padding-top: 5%;
    padding-bottom: 5%;
}

.connect-ronin-wallet-button-wrapper-half img {
    max-width: 16%;
}


.login-button-ronin:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

.logged-button-ronin:hover {
    background-color: #0056b3; /* Darker shade on hover */
}


/* Media */
@media (max-width: 1000px) {
    .ronnin-wallet-connection-wrapper {
        width: 90%;
        flex-direction: row;
    }
    .login-button-ronin {
        width: 100%;
    }
    .logged-button-ronin{
        width: 100%;
        margin-left: 0%;
        margin-bottom: 0%;
    }

    .connect-ronin-wallet-button-wrapper-half img {
        max-width: 6%;
    }
    .connect-ronin-wallet-button-wrapper img {
        max-width: 5%;
    }
}

@media (max-width: 766px){
    .ronnin-wallet-connection-wrapper {
        width: 90%;
        flex-direction: col;
    }

    .login-button-ronin {
        width: 100%;
    }

    .logged-button-ronin{
        width: 100%;
        margin-bottom: 0%;
    }

    .connect-ronin-wallet-button-wrapper-half img {
        max-width: 2%;
    }

    .connect-ronin-wallet-button-wrapper img {
        max-width: 5%;
    }
}