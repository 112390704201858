:root {
  /* Core Colors */
  --primaryColor:#121a2b;
  --secondaryColor: #02071C;
  --primaryDivisions: #334154;
  --primaryButton: #1C4ED8;
  --secondaryButton: #232d3d;
  --primaryField: #1E293B;
  --secondaryField: red;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  background: var(--primaryColor);
  color: white;
  font-family: 'Popins', sans-serif;
  font-size: 1.5rem;
}

.App {
  display: flex;
  border: 1px solid black;
  overflow: hidden;
  flex-direction: row;
  width: 100%;
}

.sideBar {
  flex: 3;
  border-right: 1px solid var(--primaryDivisions);
  /* background-color: var(--secondaryColor); */
  background-color: transparent;
}

.sideBarHidden {
  display: none;
}
.chatbotPage {
  flex: 7;
}

.logo {
  max-width: 150px;
  margin-right: 2rem;
  border-radius: 1rem;
}
.logoText {
  font-size: 2rem;
  font-weight: 700;

}
.brand {
  font-size: 2rem;
}

.upperSide {
  padding: 2.5rem;
  padding-top: 4rem;
  border-bottom: 1px solid var(--primaryDivisions);
  height: 70%;
}

.upperSideTop {
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
  justify-content: center;;
}

.addBtn {
  height: 2rem;

}
.menu-button-wrapper {
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  border-radius: 0.5rem;
  width: 100%;
  flex-direction: row;

}

.menu-panel-half {
  display: flex;
  align-items: center;
  background: transparent;
  color: rgba(222, 222, 222, 1);
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  width: 95%;
  border-radius: 0.5rem;
  border: 1px solid rgba(98, 98, 98, 1);
  justify-content: space-between;
  transition: background-color 0.5s; /* Smooth transition */

}

.menu-panel-half:hover {
  background-color: #000000d0;
  .menu-panel-half-left {
    color: red;
    animation: moneyEarning 2s infinite; /* 2 seconds duration, repeats indefinitely */
  }
}

.menu-panel-half-right {
  flex:5;
  text-align: left;
}

.menu-panel-half-left {
  flex: 3;
  transition: color 0.5s; /* Smooth transition */
}

.menu-panel {
  display: flex;
  align-items: center;
  background: transparent;
  color: rgba(222, 222, 222, 1);
  padding: 1.5rem;
  width: 90%;
  margin: 1rem auto;
  border-radius: 0.5rem;
  border: 1px solid rgba(98, 98, 98, 1)
}

.menu-panel>img {
  margin-right: 2rem;
  object-fit: cover;
  height: -1.75rem;
}

.lowerSide {
  padding: 2rem;
}

.listItems {
  margin: 0.75rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: left;
  width:100%;
}

.listItems button {
  background-color: var(--secondaryColor);
  width: 100%;
  height: 100%;
  color: white;
  font-family: 'Popins', sans-serif;
  border: none;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: left;
}

.listItems button:hover {
  background-color: var(--primaryField);
}

.listItemsIcon {
  margin: 0.75rem;

}

/* .listItems:nth-child(1)>.listItemsIcon {
  margin-right: 0.5rem;
}

.listItems:nth-child(3)>.listItemsIcon {
  margin-right: 0.75rem;
} */

.chatbotPage {
  /* margin: 2% 0%; */
  /* height: 100vh;
  width: 100%; */
}

/* Sidebar Button Logic Open and Close CSS */
.sidebarButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, var(--secondaryColor), var(--primaryColor));
  max-width: 100%;
}

.sidebarButtonWrapper button{
  background: transparent;
  border: none;
  color: white;
  border-radius: 0.5rem;
  margin: 0px;
  height: 100%;
}

.sidebarButtonWrapper button:hover {
  background: var(--primaryField);
}

.sidebarButtonWrapper button:active {
  background: var(--primaryDivisions);
}

.sidebarButtonWrapperOpen {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, var(--secondaryColor), var(--primaryColor));

}

.sidebarButtonWrapperOpen button{
  background: transparent;
  border: none;
  color: white;
  border-radius: 0.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.sidebarButtonWrapperOpen button:hover {
  background: var(--primaryField);
}

.sidebarButtonWrapperOpen button:active {
  background: var(--primaryDivisions);
}

/* Chatbot CSS */
.chatbotContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: purple;
}
.chatbot {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100dvh;
}
.chats {
  /* overflow: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  flex-direction: column-reverse;
  width: 90%;
  flex-grow: 1; 
 */
  height: 100%;
  border-radius: 10px;
  scroll-behavior: smooth;
  overflow-y: scroll;
  position: relative;
  flex-direction: column-reverse;
  width: 100%;
}
.chatImg {
  object-fit: cover;
  width: 3.5rem;
  margin-right: 2rem;
  border-radius: 0.5rem;
}

.botchat {
  background: rgba(28,30,58,1);
  width: fit-content;
  border-radius: 0.5rem;
  align-self: flex-end;
}
.chatWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 2%;
  margin-right: 12%;
  margin-left: 20%;
}

.chatWrapperBot {
  display: flex;
  justify-content: flex-start;
  margin-top: 2%;
  margin-right: 20%;
}

.chatMessageBot {
  /* margin: 1.5%;
  padding: 3% 2%;
  font-size: 100%;
  display: flex;
  text-align: justify; */

  width: 75%;
  word-wrap: break-word;
  background-color: #4f5d73c7;
  padding: 2% 3%;
  border-radius: 10px 10px 10px 0;
  margin-left: 10%;
}

.chatMessage {
  /* margin: 1.5%;
  padding: 3% 2%;
  font-size: 100%;
  display: flex;
  text-align: justify; */

  width: 75%;
  word-wrap: break-word;
  background-color: var(--primaryField);
  padding: 2% 3%;
  border-radius: 10px 10px 0 10px;
}

.chatFooter {
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  width: 92%;
  height: 11%;
}

.inp {
  background: var(--primaryField);
  display: flex;
  border-radius: 0.5rem;
  width: 100%;
}

.inp>input {
  width:100%;
  outline: none;
  padding: 1.25rem;
  color: white;
}
.inp>input, .msgInp  {
  margin-top: 0.5rem;
  background: transparent;
  border: none;
}

.chatFooter>p {
  margin: 1% 0;
  text-align: center;
}

.sendBtnImg  {
  max-width: 4rem;
  margin-right: 1rem;
}

.sendBtn {
  background: transparent;
  border: none;
}

.flexColumnDirectionPhone {
  flex-direction: row;
}

.listItems span {
  padding-left: 1rem;
}

.chatDisclaimer {
  color: rgba(255, 255, 255, 0.253);
  font-size: 1.2rem;
}

.msgLoadingImg {
  max-width: 3rem;
}

.coinsale-action-button{
  background-color: red;
}

/* KEYS */
@keyframes moneyEarning {
  0% {
    color: yellow
  }
  5% {
    color: white
  }
  10% {
    color: green;
  }
  30% {
    color: rgb(0, 255, 30); 
  }
  50% {
    color: green;
  }
  70% {
    color: green;
  }
  90% {
    color: rgb(0, 255, 30); 
  }
  100% {
    color: green;
  }
}


/* Media */
@media (max-width: 1000px) {
  .menu-panel-half{
    width: 90%;
  }
  .chatFooter>p {
    margin: 2% 0 0 0;
    text-align: center;
  }

  .menu-button-wrapper{
    flex-direction: column-reverse;
  }
}
@media (max-width: 1000px){
  .menu-panel-half{
    width: 90%;
    margin-top: 3%;
    margin-left: 0%;
  }
  .flexColumnDirectionPhone {
    flex-direction: column;
  }
  .sidebarButtonWrapper button {
    width:100%;
  }
  .sidebarButtonWrapperOpen {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, var(--secondaryColor), var(--primaryColor));
    width: 100%;
  }

  .sidebarButtonWrapperOpen button{
    background: transparent;
    border: none;
    color: white;
    border-radius: 0.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    width: 100%;
  }
  
  .sidebarButtonWrapperOpen button:hover {
    background: var(--primaryField);
  }
  
  .sidebarButtonWrapperOpen button:active {
    background: var(--primaryDivisions);
  }
  
  .menu-button-wrapper{
    flex-direction: column-reverse;
  }
}