:root {
    /* Core Colors */
    --primaryColor:#121a2b;
    --secondaryColor: #02071C;
    --primaryDivisions: #334154;
    --primaryButton: #1C4ED8;
    --secondaryButton: #232d3d;
    --primaryField: #1E293B;
    --secondaryField: red;
    --confirmButton: #97d52b;
    --confirmButtonDisabled: #c8f280;
    --removeButton: rgba(202, 67, 67, 0.703);
    --removeButtonDisabled: rgba(224, 137, 137, 0.703);
  }

  
.AlertWrapper {
    position: absolute;
    z-index: 2;
    width: 100%;

}

.LoadingPageWrapper {
    position: absolute;
    z-index: 2;
    width: 100%;
    background-color: var(--primaryColor);
}
.AlertWrapper p {
    font-size: 2dvh;
    font-weight: bold;
    font-family: 'Popins', sans-serif;
    font-size: 1.5rem;
}

@keyframes AlertWrapper {
    from { opacity: 1; }
    to { opacity: 0; }
}

.AlertWrapperInactive {
    display: none;
    position: absolute;
    z-index: 2;
    width: 100dvh;
}
